(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championships.newChampionship.controller:NewChampionshipCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.home.championships.newChampionship')
      .controller('NewChampionshipCtrl', NewChampionshipCtrl);

  function NewChampionshipCtrl($state, $mdToast, $filter, Championships, AclService,
                               genders, programmes, categories, phases, sports) {
    var vm = this;
    vm.ctrlName = 'NewChampionshipCtrl';

    vm.isAdmin = AclService.isAdmin;

    vm.genders = genders;
    vm.programmes = programmes;
    vm.categories = categories;
    vm.phases = buildPhases(phases);

    vm.sports = sports;
    vm.championshipTypes = buildChampionshipTypes();

    vm.championship = {
      programmeId: 1,
      typeId: 1,
      jvp: false,
      winPoints: 3,
      drawPoints: 2,
      lostPoints: 1,
      editableMatches: true,invisible:false
    };
    vm.saveChampionship = saveChampionship;

    function buildChampionshipTypes() {
      return [
        {
          id: 1,
          name: 'Lliga'
        }
      ];
    }

    function buildPhases(phases) {
      if (vm.isAdmin()) {
        return phases;
      } else {
        return filterLocalPhases(phases);
      }
    }

    function filterLocalPhases(phases) {
      return _.filter(phases, function (phase) {
        return phase.type === 'LOCAL';
      });
    }

    function saveChampionship() {
      Championships.save(vm.championship, function () {
        $state.go('home.championships', {}, {reload: true});
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CHAMPIONSHIPS.SUCCESSFUL_CREATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
      }, function (err) {
        var errorMessage = err.status === 400
          ? err.data.message || 'BAD_REQUEST_CHAMPIONSHIP'
          : 'SERVER_ERROR';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(errorMessage))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }
  }
}());
